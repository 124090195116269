import React from 'react'
import Link from 'gatsby-link'
import './style.css'
import { Header ,Icon, Divider } from 'semantic-ui-react'

// import Timeline from 'react-timeline-semantic-ui';

class TimelinePage extends React.Component {
    state = {
        activePoint: 5,
        timelineData:[]
    }

    componentDidMount() {
        this.getTimelineData();
    }

    getTimelineData() {
        fetch("https://api.airtable.com/v0/app1POYiufgjnmVCa/History?maxRecords=20&view=Grid%20view", {
         headers: {"Authorization": `Bearer ${process.env.GATSBY_AT_KEY}`}
       }).then((response) => response.json())
           .then((responseData) => {
               const timelineData = responseData.records
               console.log(timelineData);
               this.setState({timelineData}, () => {
                   console.log(this.state.timelineData);
               })
         })
    }

    handleNext() {
        let activePoint = this.state.activePoint
        const timeLength = this.state.timelineData.length - 1
        if (activePoint > timeLength) {
            this.setState({
                activePoint: 1
            })
        } else {
        this.setState({
            activePoint: activePoint + 1
        })
        }
        console.log(this.state.activePoint)
    }

    handleBack() {
        let activePoint = this.state.activePoint
        const timeLength = this.state.timelineData.length
        console.log(this.state.activePoint)
        if (activePoint <= 1) {
            this.setState({
                activePoint: timeLength
            })
        } else {
        this.setState({
            activePoint: activePoint - 1
        }, () =>{
            console.log(this.state.activePoint)
        })
        }
    }

    render() {
        const timelineLiStyle = {
            // position: 'absolute',
            // left: '50%',
            // transform: 'translateX(-50%)'
        }
        const TimelineLi = this.state.timelineData.map((point) => {
          if (this.state.activePoint == point.fields.number || this.state.activePoint + 1 == point.fields.number || this.state.activePoint -1 == point.fields.number){
              console.log(point)
            return (
                <div style={timelineLiStyle} className="timeline-header-li" key={point.fields.number}>
                    <div className="tl-head">
                        <div className="tl-head-year">{point.fields.year}</div>
                        <div className="tl-head-name">{point.fields.name}</div>
                        <div className="tl-head-tick"></div>
                    </div>
                </div>
            )
          }
        });
        const activeTimePoint = this.state.timelineData.map((point) => {
            if (this.state.activePoint == point.fields.number) {
            return (
                <div className="activeTimePoint" key={point.fields.number}>
                    <div className="tl-ap-year">{point.fields.year}</div>
                    <div>{point.fields.content}</div>
                </div>
                )
            }
        });
     return (
         <div className='page-wrapper'>
             <div className="section-content">
                 <h1>Timeline</h1>
                 <div className="timeline-wrapper">
                     <div className="timeline-meta-data">
                        {TimelineLi}
                    </div>
                     <div className="timeline"></div>
                     <div className="arrow-container timeline-arrow">
                         <div className="back-arrow" onClick={this.handleBack.bind(this)}>
                             <Icon name="arrow left" size="large"/>
                         </div>
                         <div className="next-arrow" onClick={this.handleNext.bind(this)}>
                             <Icon name="arrow right" size="large"/>
                         </div>
                     </div>
                 </div>
                 <div className="tl-card-container">
                     {activeTimePoint}
                 </div>

             </div>
         </div>
    )
  }

}

export default TimelinePage
